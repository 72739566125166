import React from "react"
import styled from "styled-components"
import { t } from "i18next"
import { hostProvider, appVersion } from "../shared/config"

interface DSPageFooterProps {
    showVersion: boolean
}

const DSPageFooter: React.FC<DSPageFooterProps> = ({ showVersion }) => {
    return (
        <PageFooter>
            <PageFooterContent>
                <span>{`© 2025 DocuSpeed`}</span>
                {hostProvider === "aliyun" ? <a href="http://beian.miit.gov.cn/">粤ICP备2023076036号</a> : null}
                <PageFooterLegal>
                    <a href="/legal/terms.html">{t("terms")}</a>
                    <a href="/legal/privacy.html">{t("privacy")}</a>
                </PageFooterLegal>
                <PageFooterVersion>{showVersion && <span>{`${t("version")}: ${appVersion}`}</span>}</PageFooterVersion>
            </PageFooterContent>
        </PageFooter>
    )
}

export default DSPageFooter

const PageFooter = styled.div`
    position: sticky;
    width: 100%;
    height: 110px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    padding: 15px 30px;
    box-sizing: border-box;
    background: white;
`

const PageFooterContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.6;

    @media (max-width: 500px) {
        flex-direction: column;
    }

    & span,
    & a {
        margin: 0 10px;
        color: #666;
    }

    & a {
        text-decoration: none;
    }

    & a:hover {
        color: #0052cc;
    }
`

const PageFooterLegal = styled.div`
    display: flex;
    align-items: center;
`

const PageFooterVersion = styled.div``
