import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { hostProvider } from "../../shared/config"
import { DiscordChannelInvitations, SupportMail } from "../../shared/constants"
import { DSCssSize } from "../../shared/css-constants"

const HomePageFooter: React.FC = () => {
    const { t } = useTranslation()

    return (
        <FooterWrapper>
            <FooterNav>
                <FooterCopyright>
                    <span>{`© 2025 DocuSpeed`}</span>
                    {hostProvider === "aliyun" ? <a href="http://beian.miit.gov.cn/">粤ICP备2023076036号</a> : null}
                </FooterCopyright>
                <FooterNavLinks>
                    {hostProvider === "aliyun" ? null : <a href="/price">{t("pricing")}</a>}
                    {hostProvider === "aliyun" ? null : (
                        <a
                            href={
                                hostProvider === "aliyun"
                                    ? DiscordChannelInvitations.ChatChinese
                                    : DiscordChannelInvitations.ChatEnglish
                            }
                        >
                            {t("community")}
                        </a>
                    )}
                    <a href="/legal/terms.html">{t("terms")}</a>
                    <a href="/legal/privacy.html">{t("privacy")}</a>
                    <a href={`mailto:${hostProvider === "aliyun" ? SupportMail.China : SupportMail.USA}`}>
                        {t("contact")}
                    </a>
                </FooterNavLinks>
            </FooterNav>
        </FooterWrapper>
    )
}

export default HomePageFooter

const FooterWrapper = styled.footer`
    max-width: ${DSCssSize.DesktopMaxWidth}px;
    background-color: transparent;
    color: white;
    padding: 20px 0;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        width: 100%;
        padding: 16px;
    }    

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        padding: 16px;
    }    
`

const FooterNav = styled.div`
    width: ${DSCssSize.DesktopMaxWidth}px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.6;
    box-sizing: border-box;

    & a {
        margin: 0 10px;
        color: #666;
    }

    & a {
        text-decoration: none;
    }

    & a:hover {
        color: #0052cc;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        width: 100%;
        gap: 8px;
        padding: 0 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        flex-direction: column-reverse;
        height: auto;
        gap: 8px;
    }    
`

const FooterCopyright = styled.div`
    display: flex;
    align-items: center;

    & span {
        margin-right: 10px;
        color: #666;
    }


    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        flex-direction: column-reverse;
        gap: 8px;

        & span {
            margin-right: 0;
        }        
    }    

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        flex-direction: column-reverse;
        gap: 8px;

        & span {
            margin-right: 0;
        }        
    }    
`

const FooterNavLinks = styled.div`
    display: flex;
    align-items: center;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        gap: 8px;
    }    

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        flex-direction: column;
        gap: 8px;
    }    
`
